import React from 'react';
import { useEffect, useState } from "react";
import {
  VStack,
  useDisclosure,
  Button,
  Box,
  Center,
  Text,
  Stack,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";
import { toHex, truncateAddress } from "./utils";

class Leaderboard extends React.Component { 

  constructor(rate) {
    super();
    this.state = { 
        leaders: [],
        screenW: null,
    };

  }

 
  componentDidMount()
  {
    async function apiCall() {
        const url = `/.netlify/functions/pull`;
        try {
          const response = await fetch(url);
          const data = await response.json();
          
          return data;
           
        } catch (err) {
            return null;
        }
    }



    apiCall().then(value => {this.setState({leaders: value})})

    //document.body.style.overflow = "scroll"
    //console.log(document.getElementsByClassName("chakra-ui-light")[0].style.overflowY = "scroll")
this.setState({screenW: document.body.offsetWidth})

  }


  componentDidUpdate( )
  { 

  }



     
  render()
  {  
    

    return (
        <div style={{margin: '0 auto'}} id="leaderboard">
       
               <div className="leaderboard">
             
 
                    
                    <Box overflowY="auto" maxHeight={this.state.screenW < 699 ? "60vh": "70vh"} borderRadius='xl' color='black' overflowX="hidden">
                        <Table variant='simple' overflowY="scroll" overflowX="hidden" size={this.state.screenW < 699 ? "md": "lg"} borderRadius='xl'  bg='rgba(255, 255, 255, .5);' >
                            <TableCaption fontSize="2xl" position="sticky" bg="rgba(255, 255, 255, 1);" bottom={0}>mems catcher</TableCaption>
                            <Thead position="sticky" top={0} bg="rgba(255, 255, 255, 1);" >
                            <Tr>
                                <Th fontSize={this.state.screenW < 699 ? "md": "2xl"} >Place</Th>
                                <Th fontSize={this.state.screenW < 699 ? "md": "2xl"}>Wallet</Th>
                                <Th fontSize={this.state.screenW < 699 ? "md": "2xl"} isNumeric>Score</Th>
                            </Tr>
                            </Thead>
                            <Tbody>

                                {this.state.leaders.map((user,i) => 
                                    <Tr css={this.props.walletAddress === user.wallet ? {background: 'rgba(255, 255, 255, .5);'} :{background: 'transparent'} }>
                                    <Td fontSize={this.state.screenW < 699 ? "sm": "xl"} >{i + 1}</Td>
                                    <Td fontSize={this.state.screenW < 699 ? "sm": "xl"}>{truncateAddress(user.wallet)}</Td>
                                    <Td fontSize={this.state.screenW < 699 ? "sm": "xl"} isNumeric>{user.score}</Td>
                                </Tr>
                            

                            )}

                            


                            </Tbody>
                            
                        </Table>
                    </Box>
          
                </div>

      </div>
      )
  }
}
export default Leaderboard;
