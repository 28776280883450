import React from 'react';
import { useEffect, useState } from "react";
import {
  VStack,
  useDisclosure,
  Button,
  Box,
  Center,
  Text,
  Stack
} from "@chakra-ui/react";
import SelectWalletModal from "./Modal";
import { useWeb3React } from "@web3-react/core";
import { networkParams } from "./networks";
import { connectors } from "./connectors";
import { toHex, truncateAddress } from "./utils";
import logo from './media/logo.webp';
import './styles.css';
import { Joystick } from 'react-joystick-component';
import joyImg from './media/mem.png';
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import mem from './media/mem.png';
import meatball from './media/meatball.png';
import good1 from './media/good1.png';
import good2 from './media/good2.png';
import bad from './media/bad.png';
import joystickMem  from './media/joystickMem.png'
import rulesDesktop from './media/rulesDesktop.png'
import rulesMobile from './media/rulesMobile.png'

function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  function generateRandomDecimalInRangeFormatted(min, max, places) {
    let value = (Math.random() * (max - min + 1)) + min;
    return Number.parseFloat(value).toFixed(places);
}



  function collision(div1, div2) {

    

    const domRect1 = div1.getBoundingClientRect();
    const domRect2 = div2.getBoundingClientRect();
  
    if (!(
      domRect1.top > domRect2.bottom ||
      domRect1.right < domRect2.left ||
      domRect1.bottom < domRect2.top ||
      domRect1.left > domRect2.right)
    )
    {
        
        div1.style.display = 'none'
        return true;
    }

  }

  async function apiCall(walletParam, scoreParam) {
    const url = `/.netlify/functions/push?account=${walletParam}&score=${scoreParam}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      return true;
       
    } catch (err) {
        return false;
    }

  
}

class SignalConverter {
  signalRate = 100; 
  joystickState = null; 
  constructor(rate){
    this.x = 0,
    this.actualX = 0,
    this.signalRate = rate;
    this.rightBound = 0
  }
  //provided to `move` and `start` callback
  onMove(joystickUpdate){

      if(this.rightBound === 0)
      {
        this.rightBound = document.querySelector('#square').offsetWidth - ((document.querySelector('#square').offsetWidth / 100) * 20 )
      }
      this.joystickState = joystickUpdate
      //console.log(this.joystickState.x)
      this.x = this.joystickState.x
    //  console.log(this.joystickState)
  }

  onStop() 
  {
    this.x = 0;
    //console.log("no movement anymore")
  }

  streamUpdates(callback){
      setInterval(()=> {
          callback(this.joystickState)
          if(this.x !== 0)
          {

            var memX = this.actualX + this.x / 40

            if(memX < 0) this.actualX = 0
            else if(memX > this.rightBound) ;
            else this.actualX = this.actualX + this.x / 40

           document.querySelector('#mem').style.left = this.actualX + "px"



          }    
      }, this.signalRate, this.x)
  }
}

class Play extends React.Component { 
  signalRate = 100; 
  joystickState = null; 

  constructor(rate) {
    super();
    this.state = { 
        x: 0,
        y: 0,
        activated: true,
        leftBound: 0,
        rightBound: 0,
        timer: 60,
        widthOfGame: 0,
        gameStarted: false,
        gameOver: false,
        item: 0,
        score: 0,
        scoreSubmitted: false,
        screenW: null,
        test: 0,
        mobile: false,

        if(rate) {
            this.signalRate = rate
        }
        
    };

    this.startGame = this.startGame.bind(this);
  }

 
  componentDidMount()
  {

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.setState({mobile: true});

    }  
    else {

      document.getElementById("square").style.width = "50%"
      document.addEventListener('keydown', (e) => {
        //console.log(e.code)
       // console.log(document.getElementById('mem').style.left)
        if (e.code === "ArrowLeft")       
        {
          let id = null;

          let pos = 0;
          clearInterval(id);
          id = setInterval(frame, 10);
          function frame() {
            if (pos == 10) {
              clearInterval(id);
            } else {
              pos++;

              var memX = parseInt(document.getElementById('mem').style.left.replace("px", ""))  - pos
              if(memX > 0)
              {
           
      
                document.getElementById('mem').style.left = parseInt(document.getElementById('mem').style.left.replace("px", ""))  - pos + 'px';
              }
            }
          }
        
        }
        else if (e.code === "ArrowRight") 
        {

          
          let id = null;

          let pos = 0;
          clearInterval(id);
          id = setInterval(frame, 10);
          function frame() {
            //console.log("called")
            if (pos == 10) {
              clearInterval(id);
            } else {
              pos++;

              var memX = parseInt(document.getElementById('mem').style.left.replace("px", ""))  + pos
              if(memX <  document.querySelector('#square').offsetWidth - ((document.querySelector('#square').offsetWidth / 100) * 20 ))
              {
      
              document.getElementById('mem').style.left = parseInt(document.getElementById('mem').style.left.replace("px", ""))  + pos + 'px';
              }
            }
          }

        }
      
        //document.getElementById('mem').innerHTML = 'playerSpriteX = ' + playerSpriteX;
      });
    }

    this.setState({x: document.querySelector('#mem').getBoundingClientRect().left})
    this.setState({rightBound: document.querySelector('#square').offsetWidth - ((document.querySelector('#square').offsetWidth / 100) * 20 ), widthOfGame: document.querySelector('#square').offsetWidth})
    var test = document.querySelector('#mem').offsetWidth;
    this.setState({screenW: document.body.offsetWidth})
  }


  componentDidUpdate( )
  { 

  }

  startGame() {

    this.setState({gameStarted: true, scoreSubmitted: false})

    window.localStorage.setItem('item', 0);
    window.localStorage.setItem('score', 0);
    window.localStorage.setItem('timer', 60);
    window.localStorage.setItem('gameOver', false);
    window.localStorage.setItem('scoreSubmitted', false);

    document.getElementById("endGame").style.display = "none"

    //timer
    let interval1 = setInterval(() => {

      if(parseInt(window.localStorage.getItem('timer')) === 0 )
      { 
          

            if(window.localStorage.getItem('scoreSubmitted') === "false")
            {
                for(var i = 1; i <= window.localStorage.getItem('item'); i++)
                {
                document.getElementById(i).remove()
                }

                apiCall(this.props.walletAddress, parseInt(window.localStorage.getItem('score')))

                //document.getElementById("endGame").style.display = "block";
                window.localStorage.setItem('scoreSubmitted', true);
                window.localStorage.setItem('gameOver', true);

                

                document.getElementById("endGame").style.display = "block"

                clearTimeout(interval1)
                clearTimeout(interval2)
            }
            
        } //api call to update scores
        else 
        {

           var img = document.createElement('img');

            var whichOne = getRandomInt(4);
            var trait = null;

            switch(whichOne){
                case 0:
                    img.src = meatball;
                    img.className = "meatball"
                    break;
                case 1:
                    img.src = good1;
                    img.className = "good"
                    break;
                case 2:
                    img.src = good2;
                    img.className = "good"
                    break;
                case 3:
                    img.src = bad;
                    img.className = "bad"
                    break;
                default:
                    break;

            }
            
            img.width = (this.state.widthOfGame / 100) * 10;
            img.id = parseInt(window.localStorage.getItem('item')) + 1;
            img.style.position = 'absolute';
            img.style.bottom = (this.state.widthOfGame - img.width)+ 'px';
            img.style.left = getRandomInt(this.state.widthOfGame - img.width)+ 'px';
            if(this.state.mobile) img.setAttribute('velocity',  generateRandomDecimalInRangeFormatted(.1, .4, 1))
            else img.setAttribute('velocity',  generateRandomDecimalInRangeFormatted(.1, .2, 1))
            document.getElementById('content').appendChild(img);

            window.localStorage.setItem('item', parseInt(window.localStorage.getItem('item')) + 1);


            document.getElementById("#score").innerHTML = parseInt(window.localStorage.getItem('score'))

            window.localStorage.setItem('timer', parseInt(window.localStorage.getItem("timer")) - 1);

            document.getElementById("#timer").innerHTML = parseInt(window.localStorage.getItem('timer'))
        }

      }, 1000);


        

    let interval2 = setInterval(() => {
        if(this.state.timer === 0 )
        {

        }
        else
        {

            //console.log(parseInt(window.localStorage.getItem('item')))
            
            //VELOCITY RANDOMIZE BETWEEN .1 AND 1, A LOOOP THAT RUNS THRU ALL 
            //HOW TO KNOW WHEN ONE TOUCHES THE OTHER ??

            
            if(window.localStorage.getItem("gameOver") === "false")
            {
            for(var i = 1; i <= parseInt(window.localStorage.getItem('item')); i++)
            { 
              //console.log(document.getElementById(i).style.bottom)
                document.getElementById(i).style.bottom = String(parseInt(document.getElementById(i).style.bottom.replace('px','')) - parseFloat(document.getElementById(i).getAttribute('velocity'))) + 'px';



                if(document.getElementById(i).style.bottom < 0) {document.getElementById(i).style.display = 'none'}

                if(parseInt(document.getElementById(i).style.bottom.replace('px',''))  === 0) {document.getElementById(i).style.display = 'none'}
                if(collision(document.getElementById(i), document.getElementById("mem")))
                {
                    switch(document.getElementById(i).className)
                    {
                        case "meatball":
                            window.localStorage.setItem('score', parseInt(window.localStorage.getItem('score')) + 3);
                            break;
                        case "good":
                            window.localStorage.setItem('score', parseInt(window.localStorage.getItem('score')) + 1);
                            break;
                        case "bad":
                            window.localStorage.setItem('score', parseInt(window.localStorage.getItem('score')) - 2);
                            break;
                        default:
                          window.localStorage.setItem('score', parseInt(window.localStorage.getItem('score')));
                    }
                   
            }

            }
          }
          
        }
        }, 10);

  }

  //change mobile velocity 
  render()
  {  
    const signalConverter = new SignalConverter(1);

    var response = 0;

    signalConverter.streamUpdates((state) => {
      if((state != null) && (signalConverter.x !== 0) && (signalConverter.x !== null))
      {

        //console.log(signalConverter.actualX)
      }

      
    })
    

    return (
        <div id="play">
            <Stack spacing={0} width="100%" p={0} m={0}>
                
                <div className='squareTop'>
                
                    
                    <div style={{width: "33%"}}>
                    <Text fontSize='4xl' id="#timer" style={{float: 'left'}}>{this.state.timer}</Text>
                    </div>

                    <div style={{width: "33%"}}>
                      <center>
                    <Button fontSize={this.state.screenW < 699 ? "2xl" : "3xl"} borderTopLeftRadius='xl' textAlign="center" borderTopRightRadius='xl' borderBottomRightRadius='xl' borderBottomLeftRadius='xl' opacity={1} bg='rgba(255, 255, 255, .5)' _hover={{ bg: "rgba(255, 255, 255, .5)" }} width="auto" paddingTop='.1em' margin="0 auto" onClick={e => {
                      window.location.reload()}}>home</Button>
                      </center>
                      </div>
                    <div style={{width: "33%"}}>

                    <Text fontSize='4xl' id="#score" style={{float: 'right'}}>{this.state.score}</Text>
                    </div>
                </div>
     
                <center>
                    <div className='square' id="square">
                        <div className='content' id="content">
                            {!this.state.gameStarted && <div style={{marginTop: '15%'}}>
                                <img src={this.state.mobile ? rulesMobile : rulesDesktop} style={{width: "50%"}}></img>
                                <Button fontSize={this.state.screenW < 699 ? "2xl" : "3xl"} size={this.state.screenW < 699 ? "md" : "lg"} style={{marginTop: '5%'}} onClick={this.startGame}>Start</Button></div>}
                            <div id="endGame" style={{marginTop: '20%', display: 'none'}}>

                          {/*`I scored ${this.state.score} points in mems catcher! Play now!`, via: 'memnfts'*/}

                              
                            <Button  fontSize={this.state.screenW < 699 ? "2xl" : "3xl"} size={this.state.screenW < 699 ? "md" : "lg"} style={{marginTop: '5%'}} >
                              <a id="tweet"
                                target="_blank"
                                href="https://twitter.com/intent/tweet?text=Hello%20world"
                                onClick={e => {
                                  e.preventDefault()
                                 

                                  window.open(`https://twitter.com/intent/tweet?text=I%20scored%20${localStorage.getItem("score")}%20points%20in%20mems%20catcher!%20Play%20Now!%0Amemscatcher.com%0Avia%20%40memnfts`, '_blank');

                                }}>
                                  
                              Tweet</a></Button>
                            <br></br>
                            <Button fontSize={this.state.screenW < 699 ? "2xl" : "3xl"} size={this.state.screenW < 699 ? "md" : "lg"} style={{marginTop: '5%'}} onClick={this.startGame}>Restart</Button>
                            <br></br>
                            <Button fontSize={this.state.screenW < 699 ? "2xl" : "3xl"} size={this.state.screenW < 699 ? "md" : "lg"} style={{marginTop: '5%'}} onClick={e => {window.location.reload()}}>Home</Button></div>
                            



                            <img id="mem" src={mem} style={{height: '20%', position: 'absolute', bottom: '7%', left: !this.state.activated ? '80%' : signalConverter.actualX, display: 'block'}}></img>
                           
                        </div>
                    </div>  
                </center>

                {this.state.mobile && <center>
                    <br></br>
                    <Joystick id="joystick" size={100} sticky={false} baseColor="rgba(255, 255, 255, .5)" stickImage={joystickMem} followCursor={false} minDistance={0} move={(update) => signalConverter.onMove(update)} start={(update) => signalConverter.onMove(update)} stop={() => signalConverter.onStop()} ></Joystick>
                    
                </center>}
            </Stack>
      </div>
      )
  }
}
export default Play;
