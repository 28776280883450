import { useEffect, useState } from "react";
import {
  VStack,
  useDisclosure,
  Button,
  Box,
  Center,
  Text,
  Stack
} from "@chakra-ui/react";
import SelectWalletModal from "./Modal";
import { useWeb3React } from "@web3-react/core";
import { networkParams } from "./networks";
import { connectors } from "./connectors";
import { toHex, truncateAddress } from "./utils";
import logo from './media/logo.webp';
import './styles.css';
import Play from './Play';
import Leaderboard from "./Leaderboard";


export default function Home() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    library,
    chainId,
    account,
    activate,
    deactivate,
    active
  } = useWeb3React();
  const [signature, setSignature] = useState("");
  const [error, setError] = useState("");
  const [network, setNetwork] = useState(undefined);
  const [message, setMessage] = useState("");
  const [signedMessage, setSignedMessage] = useState("");
  const [verified, setVerified] = useState();
  const [page, setPage] = useState("Home")
  const screenW = document.body.offsetWidth

  
  
  const [validState, setValid] = useState(localStorage.getItem('valid'));

  const refreshState = () => {
    window.localStorage.setItem("provider", undefined);
    setNetwork("");
    setMessage("");
    setSignature("");
    setVerified(undefined);
  };

  const disconnect = () => {
    refreshState();
    deactivate();

  };



  useEffect(() => {
    //once a provider is picked, something is activated
    var provider = window.localStorage.getItem("provider");
   
    
    if (provider) 
    {
      activate(connectors[provider]);
    }

    
    
  }, []);

  useEffect(() => {

    
    //once a provider is picked, something is activated /.netlify/functions/hello-world'/.netlify/functions/functionname?parameter=${parameter}`;
    async function apiCall(parameter) {
      const url = `/.netlify/functions/memsHolder?parameter=${parameter}`;
      try {
        const response = await fetch(url);
        const data = await response.json();
        window.localStorage.setItem('valid', data.msg);
        setValid(window.localStorage.getItem("valid"));
         
      } catch (err) {
          console.log(err);
      }

    
  }

    if(!active)
    {
      window.localStorage.setItem('valid', 'false');
      setValid(window.localStorage.getItem("valid"));

    
    }
    else {
      apiCall(account)
    }


  
  }, [active]); //this is a comment lol . 
  

  return (
    <>
    <div className="verticalAlign" id="app">
      <div className="view">
        {page === "Home" && <div className="home"><img src={logo} className="logo"></img>
        <div className="menu">
          <Box borderRadius='xl' bg='rgba(255, 255, 255, .5);' color='black' opacity={1} px={4} border='10px' borderColor='gray.200' p={0} m={0}>
           <center>
            <Stack spacing={0} width="100%" p={0} m={0}>
              <Button id="play" fontSize={screenW < 699 ? "3xl" : "4xl"} borderTopLeftRadius='xl' borderTopRightRadius='xl' borderBottomRightRadius='0' borderBottomLeftRadius='0' opacity={1} bg='transparent' _hover={{ bg: "rgba(255, 255, 255, .5)" }} width="100%" lineHeight={0} isDisabled={validState === "true" ? false : true} height='80px' paddingTop='.2em' onClick={e => {setPage("Play")}}>{validState === "true" ? 'Play' : 'Play🔒'}</Button>
              <Button id="#leaderboardButton" fontSize={screenW < 699 ? "3xl" : "4xl"} borderRadius='0' opacity={1} bg='transparent' _hover={{ bg: "rgba(255, 255, 255, .5)" }} width="100%" paddingTop='.1em' height='80px' onClick={e => {setPage("Leaderboard")}}>Leaderboard</Button>
              <Button id="buyAmem" fontSize={screenW < 699 ? "3xl" : "4xl"} borderTopLeftRadius='0' borderTopRightRadius='0' borderBottomRightRadius='xl' borderBottomLeftRadius='xl' opacity={1} bg='transparent' _hover={{ bg: "rgba(255, 255, 255, .5)" }} width="100%" paddingTop='.1em' height='80px' onClick={e => {window.open("https://market.memnfts.com/", '_blank').focus();}} >Buy a mem</Button>
          </Stack>



           </center>
          </Box>
        </div>
        
        
          <center>
          {!active ? (
            <Button fontSize='2xl' onClick={onOpen}>Connect Wallet</Button>
          ) : (
            <Button fontSize='2xl' onClick={disconnect}>Disconnect</Button>
          )}
   
        {(active) && <Text id="notAHolder" fontSize={'2xl'} marginTop='.5em'>{validState === "false" && <a>not a holder<br></br></a>}{truncateAddress(account)}</Text>}
        {(!active) && <Text id="exclusive" fontSize='2xl' marginTop='.5em'>exclusive to mems holders</Text>}
        </center>
      <SelectWalletModal isOpen={isOpen} closeModal={onClose} /></div>}


      {page === "Leaderboard" && <center><Button fontSize={screenW < 699 ? "2xl" : "3xl"} borderTopLeftRadius='xl' textAlign="center" borderTopRightRadius='xl' borderBottomRightRadius='xl' borderBottomLeftRadius='xl' opacity={1} bg='rgba(255, 255, 255, .5)' _hover={{ bg: "rgba(255, 255, 255, .5)" }} width="auto" paddingTop='.1em' margin="0 auto" marginBottom="2%" onClick={e => {
        if(page === "Leaderboard") setPage("Home")
        else window.location.reload()
        
        }}>HOME</Button></center>}
      {page === "Play" && <div className="play">
            
              
       <Play walletAddress={account}></Play>
  
        </div>}

      {page === "Leaderboard" && <div className="leaderboard">
          
            
      <Leaderboard walletAddress={account}></Leaderboard>

      </div>}

      

     
      </div>
      </div>
    </>
  );
}

